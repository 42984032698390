@import "@fontsource/ibm-plex-mono";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "IBM Plex Mono", "Courier New", Courier, monospace;
    }

    body {
        @apply bg-black;
        @apply text-stone-100;
    }
}

.delayed-render {
    transition: opacity 500ms;
}

.delayed-render.delayed {
    opacity: 0;
}

.grecaptcha-badge {
    visibility: hidden;
}
